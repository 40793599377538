<template>
  <zg-section
    width="xl"
    class="hero-cta"
    no-y-padding
  >
    <div class="box">
      <div class="row row--two-columns">
        <div class="column">
          <h2 v-html="highlightedTitle" />
          <p>{{ subTitle }}</p>

          <div class="usps row row--two-columns">
            <zg-usps
              v-if="usps"
              :usps="usps"
              direction="vertical"
            />
          </div>

          <div class="row hide-mobile">
            <hero-trustpilot
              v-if="trustpilotData"
              :data="trustpilotData"
            />
          </div>
        </div>

        <div class="column">
          <fragment
            :headers="formFragment.headers as Record<string, string>"
            :timeout="10000"
            :url="formFragment.url"
            render-mode="server"
          />

          <div class="row hide-desktop">
            <hero-trustpilot
              v-if="trustpilotData"
              :data="trustpilotData"
            />
          </div>
        </div>
      </div>
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, type PropType } from 'vue'
  import { getFragment } from '../../../../composables/get-fragment'
  import { highlightTitle } from '../../../../utils/higlight-title'

  const HeroTrustpilot = defineAsyncComponent(async () => await import('./hero-trustpilot.vue'))
  const Fragment = defineAsyncComponent(async () => await import('@ocp-zmarta/zsc-plugin-vite-ssr/vue').then(m => m.Fragment))
  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
  const ZgUsps = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgUsps))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: null
    },
    subTitle: {
      type: String,
      required: false,
      default: null
    },
    usps: {
      type: Array as PropType<object[]>,
      required: false,
      default: null
    },
    trustpilotData: {
      type: Object,
      required: false,
      default: () => {}
    }
  })

  const highlightedTitle = computed(() => {
    return highlightTitle(ctx?.market as string, props.title)
  })

  const formFragment = computed(() => {
    return getFragment({
      name: 'clForm',
      path: '/cl-form/calculator',
      query: {
        disclaimer: true,
        locale: ctx?.locale,
        op_user_id: ctx?.userId
      },
      ctx
    })
  })
</script>

<style lang="scss" scoped>
  .hero-cta {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    background-color: transparent !important; /* stylelint-disable-line */

    @include mq(medium) {
      margin: 0;
      padding: rhythm(10) 0 !important; /* stylelint-disable-line */
    }
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 rhythm();

    @include mq(medium) {
      flex-direction: row;
    }

    &:last-child {
      margin: 0;
    }

    &--two-columns {
      flex-direction: column;
      justify-content: space-between;

      @include mq(medium) {
        flex-direction: row;
      }
    }
  }

  .column {
    position: relative;
    width: 100%;
    margin: 0 0 rhythm();

    @include mq(medium) {
      width: calc(50% - #{rhythm()});
      margin: 0;
    }
  }

  .box {
    background-color: #fff;
    padding: rhythm(small);
    margin: 0 (- rhythm(small));

    @include mq(medium) {
      background-color: rgb(255 255 255 / 95%);
      border-radius: radius(box);
      box-shadow: box-shadow();
      padding: rhythm();
      margin: 0;
    }
  }

  h2 {
    color: #24385b;

    :deep(span) {
      color: #da8b2d;
    }
  }

  p {
    color: black;
  }

  :deep(.zg-usps) {
    font-size: 1rem !important; /* stylelint-disable-line */
    color: black !important; /* stylelint-disable-line */

    .icon {
      color: #da8b2d !important; /* stylelint-disable-line */
    }

    svg {
      fill: #da8b2d !important; /* stylelint-disable-line */
    }
  }

  .hide-mobile {
    @include mq($until: medium) {
      display: none;
    }
  }

  .hide-desktop {
    @include mq(medium) {
      display: none;
    }
  }
</style>
