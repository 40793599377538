<template>
  <zg-cross-sell-section
    :items="mappedItems"
  />
</template>

<script setup lang="ts">
  import { computed, defineAsyncComponent } from 'vue'

  const ZgCrossSellSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgCrossSellSection))

  const props = defineProps({
    items: {
      type: Array,
      required: false,
      default: null
    }
  })

  const mappedItems = computed(() => {
    return props.items?.map(item => ({
      title: item.title,
      usps: item.usps,
      button: item.button
    }))
  })
</script>

<style lang="scss" scoped>
  :deep(.zg-cross-sell) {
    color: #fff !important; /* stylelint-disable-line */
    background-color: #24385b !important; /* stylelint-disable-line */
  }
</style>
